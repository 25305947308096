<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <Ffly4uAuthLogo />
              <DropdownTranslate />
              <h1>{{ $t('auth.register.title') }}</h1>
              <p>
                {{ $t('auth.register.description') }}
              </p>
              <v-form
                v-model="valid"
                v-on:submit.prevent="register">
                <v-text-field
                  autocomplete="email"
                  v-model="email"
                  :label="$t('auth.emailAddress')"
                  :rules="[rules.required, rules.email]"
                  backgroundColor="white"
                  solo
                  append-icon="mdi-email"
                ></v-text-field>
                <v-text-field
                  autocomplete="username"
                  v-model="username"
                  :label="$t('auth.username')"
                  :rules="[rules.required, rules.username]"
                  backgroundColor="white"
                  solo
                  append-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                  autocomplete="new-password"
                  v-model="password"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  :rules="[rules.required, rules.min]"
                  name="input-10-1"
                  :label="$t('auth.password')"
                  v-on:click:append="passwordVisibility = !passwordVisibility"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-text-field
                  autocomplete="new-password"
                  v-model="confirmPassword"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  :rules="[rules.required, rules.confirmed]"
                  name="input-10-1"
                  :label="$t('auth.confirmPassword')"
                  v-on:click:append="passwordVisibility = !passwordVisibility "
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-layout align-center justify-center row fill-height>
                  <v-btn
                    type="submit"
                    class="white--text"
                    color="primary">{{ $t('auth.register.button') }}
                  </v-btn>
                </v-layout>
                <div class="my-6">
                  <p class="error--text" v-if="error">{{ $t('auth.register.error') }}
                  </p>
                </div>
                <div class="my-6">
                  <v-layout align-end justify-space-around row fill-height>
                    <router-link :to="{ name: 'login'}">
                      {{ $t('auth.alreadyAccount') }}
                    </router-link>
                  </v-layout>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Ffly4uAuthLogo from '@/components/Ffly4uAuthLogo.vue';

export default {
  name: 'Register',
  components: { Ffly4uAuthLogo },
  data() {
    return {
      passwordVisibility: false,
      password: '',
      confirmPassword: '',
      username: '',
      email: decodeURIComponent(this.$route.query.email || ''),
      error: false,
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('misc.required'),
        min: (v) => v.length >= 8 || this.$t('misc.min8Chars'),
        confirmed: (value) => value === this.password || this.$t('misc.differentPassword'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          // Regex find on text-field vuetify
          return pattern.test(value) || this.$t('misc.invalidEmail');
        },
        username: (v) => (v.length >= 1 && v.length <= 128) || this.$t('misc.invalidUsername'),
      },
    };
  },
  methods: {
    async register() {
      try {
        await this.$store
          .dispatch('auth/REGISTER', {
            username: this.username,
            email: this.email,
            password: this.password,
          });
        const data = {
          loading: false,
          title: this.$t('auth.passwordNew.success'),
          description: this.$t('auth.register.successDescription'),
          color: 'success',
          timeout: 5000,
        };
        this.$store.commit('snackbars/ADD_MESSAGE', data);
        this.$router.push({
          name: 'login',
          query: { username: this.username },
        });
      } catch (error) {
        this.error = error.message;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('auth.register.title'),
    };
  },
};
</script>
